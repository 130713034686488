<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getScuoleList')">
          <div class="row pb-4">
            <div class="col-sm-4 filter">
              <SelectInput
                :options="scuole_stati"
                name="scuole_stati"
                placeholder="Stato"
                @changeSelect="setStato($event)"
                :value="id_Stato"
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="discipline"
                :value="id_Disciplina"
                name="discipline"
                placeholder="Disciplina"
                @changeSelect="
                  setDisciplina($event);
                  getTipologieScuole($event);
                  spliceTipologie();
                  selectedTipologia = null;
                "
              />
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="scuole_tipo"
                :value="id_Tipologia"
                name="scuole_tipo"
                placeholder="Tipologia"
                @changeSelect="setTipologia($event)"
                :disabled="!id_Disciplina"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società"
                  name="denominazione_societa"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa';
                    getSocieta($event.target.value);
                  "
                  :value="nome_Societa"
                  autocomplete="off"
                />
                <svg
                  @click="setSocieta({ name: null, value: null })"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  resetSocieta();
                  setSocieta($event);
                  inputFocus = null;
                "
              ></ListSocieta>
            </div>
            <div class="col-sm-4 filter">
              <SelectInput
                :options="comitati"
                name="comitati"
                placeholder="Comitato"
                @changeSelect="setComitato($event)"
                :value="id_Comitato"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600"
              @click="$emit('getScuoleList')"
              :disabled="loading"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                spliceTipologie();
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-scuole-tennis",
  emits: ["resetFilters", "getScuoleList"],
  props: {
    resetted: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  components: { ListSocieta, SelectInput },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const scuole_stati = computed(() =>
      store.getters.getStateFromName("scuole_stati")
    );
    const comitati = computed(() => store.getters.getStateFromName("comitati"));

    const keys = ref("d|ss|c");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (!discipline.value || !scuole_stati.value || !comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const id_Stato = computed(() => store.getters.idStatoScuole);
    const id_Disciplina = computed(() => store.getters.idDisciplinaScuole);
    const id_Tipologia = computed(() => store.getters.idTipologiaScuole);
    const id_Societa = computed(() => store.getters.idSocietaScuole);
    const id_Comitato = computed(() => store.getters.idComitatoScuole);
    const nome_Societa = computed(
      () => store.getters.societaDenominazioneScuole
    );

    const setStato = (event) => {
      store.commit("setidStatoScuole", event);
    };

    const setComitato = (event) => {
      store.commit("setidComitatoScuole", event);
    };

    const setDisciplina = (event) => {
      store.commit("setidDisciplinaScuole", event);
    };

    const setTipologia = (event) => {
      store.commit("setidTipologiaScuole", event);
    };

    const getTipologieScuole = (id) => {
      store.dispatch("setStoreData", {
        keys: { id_Disciplina: id },
        apiLink: globalApi.LOOKUP_TIPO_SCUOLE,
      });
    };

    const spliceTipologie = () => {
      console.log();
      store.commit("spliceArrayFromName", "scuole_tipo");
    };

    const inputFocus = ref(null);

    const getSocieta = (string) => {
      store.commit("setsocietaDenominazioneScuole", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const setSocieta = ({ value, name }) => {
      store.commit("setidSocietaScuole", value);
      store.commit("setsocietaDenominazioneScuole", name);
    };

    const anno = computed(() => store.getters.stagioneSelected);
    watch(anno, () => {
      emit("getScuoleList");
    });

    return {
      inputFocus,
      getTipologieScuole,
      spliceTipologie,
      scuole_tipo: computed(() =>
        store.getters.getStateFromName("scuole_tipo")
      ),
      id_Stato,
      id_Disciplina,
      id_Tipologia,
      id_Comitato,
      setStato,
      setDisciplina,
      setTipologia,
      societa: computed(() => store.getters.societaNomeCodAff),
      discipline,
      scuole_stati,
      getSocieta,
      resetSocieta,
      setSocieta,
      id_Societa,
      nome_Societa,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedscuole_list")
      ),
      comitati,
      setComitato,
    };
  },
});
</script>

<style></style>
