import services from "../axios/dbManag";
import Swal from "sweetalert2";

export const viewScuola = (id) => {
  return services.dbManag
    .post("/scuole/richieste/view", {
      id,
    })
    .then((res) => {
      const scuola = res.data.results[0];

      return scuola;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addScuola = (scuola) => {
  return services.dbManag
    .post("/scuole/richieste/add", {
      ...scuola,
    })
    .then((res) => {
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const b = Swal.getHtmlContainer().querySelector("b");
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editScuola = (scuola) => {
  return services.dbManag
    .post("/scuole/richieste/edit", {
      ...scuola,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addInsegnante = (insegnante) => {
  return services.dbManag
    .post("/scuole/altri/insegnanti/add", {
      ...insegnante,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const canRequestScuole = () => {
  return services.dbManag
    .post("/lookup/scuole/nuova-richiesta/anni/list", {})
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e.response;
    });
};

export const checkAffiliazioneAnno = (affiliazioneObj) => {
  return services.dbManag
    .post("/affiliazioni/societa/anno/check", {
      ...affiliazioneObj,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
