import services from "../axios/dbManag";

export const richiesteApprove = (
  id_stato_approvazione,
  id_richieste_scuole
) => {
  return services.dbManag
    .post("/scuole/richieste/approve", {
      id_stato_approvazione: id_stato_approvazione,
      id_richieste_scuole: id_richieste_scuole,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const richiesteRefuse = (id_Richiesta_Scuole) => {
  return services.dbManag
    .post("/scuole/richieste/refuse", {
      id_Richiesta_Scuole: id_Richiesta_Scuole,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const richiesteDel = (id_Richiesta_Scuole) => {
  return services.dbManag
    .post("/scuole/richieste/del", {
      id_Richiesta_Scuole: id_Richiesta_Scuole,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
