<template>
  <div>
    <FilterScuoleTennis
      @getScuoleList="searchScuoleList"
      @resetFilters="resetFilters"
    />
    <TableScuoleTennis
      @getScuoleList="searchScuoleList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import FilterScuoleTennis from "@/components/components-fit/SNM/scuole-tennis/FiltriScuoleTennis.vue";
import TableScuoleTennis from "@/components/components-fit/SNM/scuole-tennis/TableScuoleTennis.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "scuole",
  components: { FilterScuoleTennis, TableScuoleTennis },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Lista Scuole", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(() => store.getters.rowsToSkipScuole);
    const fetchRows = computed(() => store.getters.fetchRowsScuole);
    const sortColumn = computed(() => store.getters.sortColumnScuole);
    const sortOrder = computed(() => store.getters.sortOrderScuole);
    const tutteStagioniScuole = computed(
      () => store.getters.tutteStagioniScuole
    );

    const anno = computed(() => store.getters.stagioneSelected);

    const id_Stato = computed(() => store.getters.idStatoScuole);
    const id_Disciplina = computed(() => store.getters.idDisciplinaScuole);
    const id_Tipologia = computed(() => store.getters.idTipologiaScuole);
    const id_Societa = computed(() => store.getters.idSocietaScuole);
    const id_Comitato = computed(() => store.getters.idComitatoScuole);

    const getScuoleList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          anno: tutteStagioniScuole.value ? 10000 : anno.value,
          id_stato: id_Stato.value,
          id_disciplina: id_Disciplina.value,
          id_tipologia: id_Tipologia.value,
          id_societa: id_Societa.value,
          id_comitato: id_Comitato.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.SCUOLE_LIST,
        itemName: "scuole_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersScuole");
      getScuoleList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedscuole_list")
    );

    const searchScuoleList = () => {
      if (!loaded.value) return;
      // store.commit("setRowsToSkipScuole");
      getScuoleList();
    };

    getScuoleList();

    return {
      getScuoleList,
      resetFilters,
      searchScuoleList,
      tutteStagioniScuole,
    };
  },
});
</script>
