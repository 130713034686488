<template>
  <!--begin::details View-->
  <hr class="mb-4 mt-4" />
  <div class="row mb-0">
    <div class="col-md-5 mb-0">
      <label class="fw-bold text-gray-600">Qualifica*</label>
      <SelectInput
        :name="`carica-${index}`"
        :id="`carica-${index}`"
        :options="tipo_tecnico"
        :value="figuraSocieta.id_Tipo_Tecnico"
        @changeSelect="
          figuraSocieta.id_Tipo_Tecnico = parseInt($event);
          $emit('setPersona', {
            ...figuraSocieta,
            id_Tipo_Tecnico: parseInt($event),
          });
          figuraSocieta.nominativo_completo = null;
          figuraSocieta.id_Persona = null;
        "
        :disabled="!id_Tipologia"
      />
    </div>
    <div class="col-md-5 mb-0">
      <label class="fw-bold text-gray-600">Persona*</label>
      <form autocomplete="off">
        <div class="dp__input_wrap">
          <!-- {{ inputFocusArr }}{{ index }}{{ inputFocusArr === index }} -->
          <input
            :name="`persona-${index}`"
            type="text"
            class="form-control"
            placeholder=""
            aria-label=""
            v-model="figuraSocieta.nominativo_completo"
            @input="
              getTecnici($event.target.value);
              inputFocusArr = index + 6;
            "
            autocomplete="off"
            :disabled="!figuraSocieta.id_Tipo_Tecnico"
          />
          <input
            type="hidden"
            :id="`idP-${index}`"
            class="form-control"
            v-model="figuraSocieta.id_Persona"
          />
          <ListPersone
            :no_new="true"
            :list="tecnici"
            @hideList="
              resetTecnici();
              inputFocusArr = null;
            "
            @selectedLegale="
              figuraSocieta.nominativo_completo = $event.name;
              figuraSocieta.id_Persona = $event.value;
              $emit('setPersona', {
                ...figuraSocieta,
                nominativo_completo: $event.name,
                id_Persona: $event.value,
              });
              inputFocusArr = null;
              resetTecnici();
            "
            :focusOnInput="inputFocusArr === index + 6"
            v-if="inputFocusArr === index + 6"
          />
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            class="dp__icon dp__clear_icon dp__input_icons"
            @click="
              $emit('setPersona', {
                ...figuraSocieta,
                nominativo_completo: null,
                id_Persona: null,
              });
              figuraSocieta.nominativo_completo = '';
              figuraSocieta.id_Persona = null;
            "
          >
            <path
              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
            ></path>
            <path
              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
            ></path>
          </svg>
        </div>
      </form>
    </div>
    <div
      class="col-md-1 d-flex fw-bold text-gray-700 text-center mt-5 text-danger"
    >
      <button
        class="badge bg-light-danger rounded fs-5 me-2"
        @click.prevent="$emit('deleteItem', index)"
      >
        <i class="bi bi-trash text-danger"></i>
      </button>
      <Popper
        v-if="figuraSocieta.id_Tipo_Tecnico"
        arrow
        :content="
          figuraSocieta.id_Tipo_Tecnico == 1
            ? msgDirettoreTecnico
            : figuraSocieta.id_Tipo_Tecnico == 2
            ? msgInsegnante
            : figuraSocieta.id_Tipo_Tecnico == 3
            ? msgPreparatoreFisico
            : figuraSocieta.id_Tipo_Tecnico == 4
            ? msgPreparatoreMentale
            : figuraSocieta.id_Tipo_Tecnico == 5
            ? msgEducatoreAlimentare
            : ''
        "
        style="margin: -8px; align-self: center"
      >
        <i class="bi bi-info-circle-fill text-center"></i>
      </Popper>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import ListPersone from "@/components/components-fit/utility/ListPersone.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import { useStore } from "vuex";
import "vue3-date-time-picker/dist/main.css";
import Popper from "vue3-popper";

export default defineComponent({
  name: "aggiungiFIgura",
  components: {
    ListPersone,
    SelectInput,
    Popper,
  },
  emits: ["deleteItem", "setPersona", "mountedComponent"],
  props: {
    index: {
      type: Number,
      required: true,
    },
    tecnico: {
      type: Object,
    },
    societa: {
      type: Object,
      required: true,
    },
    id_Societa: {
      type: Number,
      required: true,
    },
    // anni: {
    //   type: Array,
    //   required: true,
    // },
    anni: {
      type: [Array, Number],
      required: true,
    },
    id_Disciplina: {
      type: [String, Number],
      required: true,
    },
    id_Tipologia: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props, { emit }) {
    emit("mountedComponent");
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const tipo_tecnico = computed(() =>
      store.getters.getStateFromName("tipo_tecnico")
    );
    const keys = ref("tt");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (!tipo_tecnico.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const figuraSocieta = ref({});

    onMounted(() => {
      figuraSocieta.value = props.tecnico ? props.tecnico : {};
    });

    const getTecnici = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      // store.dispatch("getPersoneNomeCognome", string);
      store.dispatch("getTecniciNomeCognome", {
        string: string,
        anno: props.anni[0] ? props.anni[0].id : props.anni,
        id_Societa: props.societa ? props.societa.id : props.id_Societa,
        tipo_Albo: figuraSocieta.value.id_Tipo_Tecnico == 3 ? 11 : 1,
        id_Disciplina: props.id_Disciplina,
        id_tipologia_scuola: props.id_Tipologia,
      });
    };
    const resetTecnici = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const resetFigureAdd = () => {
      figuraSocieta.value.nominativo_completo = "";
      figuraSocieta.value.id_Persona = null;
      figuraSocieta.value.id_Tipo_Tecnico = null;
    };

    const inputFocusArr = ref(null);

    watch(
      () => props.id_Disciplina,
      () => {
        resetFigureAdd();
      }
    );

    const msgDirettoreTecnico = ref(
      "Il direttore tecnico deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola."
    );
    const msgPreparatoreFisico = ref(
      "Il preparatore fisico deve essere tesserato presso il circolo"
    );
    const msgInsegnante = ref(
      "L'insegnante deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola."
    );
    const msgEducatoreAlimentare = ref(
      "L'educatore alimentare deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola."
    );
    const msgPreparatoreMentale = ref(
      "Il preparatore mentale deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola."
    );

    watch(
      () => props.id_Tipologia,
      () => {
        if (props.id_Tipologia != 1) {
          msgPreparatoreFisico.value =
            "Il preparatore fisico deve essere tesserato presso il circolo, presente nella lista dei tecnici del circolo e deve avere qualifiche relative alla disciplina della scuola.";
        } else {
          msgPreparatoreFisico.value =
            "Il preparatore fisico deve essere tesserato presso il circolo";
        }
      }
    );

    return {
      figuraSocieta,
      getTecnici,
      resetTecnici,
      tecnici: computed(() => store.getters.personeSocieta),
      tipo_tecnico,
      inputFocusArr,
      resetFigureAdd,
      msgEducatoreAlimentare,
      msgPreparatoreMentale,
      msgDirettoreTecnico,
      msgPreparatoreFisico,
      msgInsegnante,
    };
  },
});
</script>

<style scoped>
/* tooltip */
:deep(.popper) {
  background: #fff !important;
  padding: 5px !important;
  border-radius: 5px !important;
  border: 1px solid #8b8b8b !important;
  color: rgb(0, 0, 0) !important;
  font-weight: bold !important;
  text-transform: none !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #fff !important;
  border: 1px solid #8b8b8b;
  left: 0px !important;
  top: -1px !important;
  visibility: visible !important;
  content: "" !important;
  transform: rotate(225deg) !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #fff !important;
  left: 0px !important;
}
</style>
